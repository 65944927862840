import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import {Global} from '../utils/Global';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { format, useRut } from 'react-rut';
import HTMLRenderer from 'react-html-renderer';
import banner1 from '../assets/img/banner1.png';
import banner2 from '../assets/img/banner4.png'

const api_url=Global.apiurl;
const base_url=Global.base;
const url_descuentos=Global.url_descuentos;

async function cargarProductos(agenda_id = null) {


  var raw = JSON.stringify({
    "agenda_id": agenda_id
  });

  var requestOptions = {
    method: 'POST',
    body: raw
  };

  const respuesta = await fetch(api_url+"products", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    })
    .catch(error => console.log('error', error));
  return respuesta;
}

async function cargarEvento(slug = null) {

  var requestOptions = {
    method: 'get'
  };

  const respuesta = await fetch(api_url+"events/" + slug, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    })
    .catch(error => console.log('error', error));
  return respuesta;
}

async function iniciarPago(payload) {

  var raw = JSON.stringify(payload);

  var requestOptions = {
    method: 'POST',
    body: raw
  };

  const respuesta = await fetch(api_url+"paymentGateway", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    })
    .catch(error => console.log('error', error));
  return respuesta;
}

async function cargarDescuento(rut,evento_id) {
 // var raw = JSON.stringify(rut);
  var requestOptions = {
    method: 'get',
    //body: raw
  };

  const respuesta = await fetch(api_url+"descuento/"+rut+"/"+evento_id, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    })
    .catch(error => console.log('error', error));
  return respuesta;
}

async function cargarComuna(payload) {
  var raw = JSON.stringify(payload);
  var requestOptions = {
    method: 'get',
    body: raw
  };

  const respuesta = await fetch(api_url+"comunas", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    })
    .catch(error => console.log('error', error));
  return respuesta;
}


const Evento = () => {
  const navigate = useNavigate();
  const [{ formattedValue }, setRut] = useRut();
  const [productos, setproductos] = useState(null);
  const [evento, setevento] = useState(null);
  const [token_ws, settoken_ws] = useState('');
  const [url_ws, seturl_ws] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const slug = searchParams.get('q')
  const [cargando, setcargando] = useState(true);
  const [inputValues, setInputValues] = useState({})

  const [email, setemail] = useState(null);
  const [email2, setemail2] = useState(null);

  const [nombre, setnombre] = useState(null);
  const [apellido, setapellido] = useState(null);
  const [telefono, settelefono] = useState(null);
  const [comuna, setcomuna] = useState(null);

  const [emailError, setemailError] = useState(false);
  const [email2Error, setemail2Error] = useState(false);

  const [nombreError, setnombreError] = useState(false);
  const [apellidoError, setapellidoError] = useState(false);
  const [telefonoError, settelefonoError] = useState(false);
  const [rutError, setrutError] = useState(false);
  const [comunaError, setcomunaError] = useState(false);

  const [comunas, setcomunas] = useState(null);

  const [totalCarrito, settotalCarrito] = useState(0);
  const [totalDescuento, settotalDescuento] = useState(0);
  const [subTotalDescuento, setsubTotalDescuento] = useState(0);
  const [total, settotal] = useState(0)

  const [descuento, setdescuento] = useState(null);
  const [mensajeDescuento, setmensajeDescuento] = useState(null);
  const [maximoProductos, setmaximoProductos] = useState(10);
  const [descripcionHtml, setdescripcionHtml] = useState(null);

  //MÁXIMO X JORNADA EVENTO
  const [maximoJornada, setmaximoJornada] = useState(null);

   // Obtenemos la fecha actual
  const fechaActual = new Date();

   // Definimos la fecha objetivo (16 de septiembre de 2023)
  const fechaObjetivo = new Date('2023-09-16');
 
   // Comparamos las fechas
  //const mostrarComponenteDescuento = fechaActual < fechaObjetivo;
  const mostrarComponenteDescuento = true;

  let carrito = [];

  const comunaRef = useRef(null);

  const calcularTotalDescuento=()=>{
      
      let total_d = 0;
      if (descuento != null) {
        descuento.forEach((p) => {
          if (typeof inputValues[p.producto_id] !== 'undefined' && inputValues[p.producto_id]>0) {
            console.log(p.nombre,'cantidad de p: ',inputValues[p.producto_id]);
            let temp_total=0;
            const cantidad_productos_con_descuento=parseInt(p.cantidad_productos_con_descuento);
            const descuento_por_producto=parseInt(p.descuento_por_producto);
            const disponibles_para_descuento=parseInt(p.disponibles_para_descuento);
            if (inputValues[p.producto_id]>0) {

              if (descuento_por_producto>0) {
                if (inputValues[p.producto_id]<=cantidad_productos_con_descuento && inputValues[p.producto_id]<=disponibles_para_descuento && descuento_por_producto>0) {
                  temp_total=(p.valor * inputValues[p.producto_id])-(inputValues[p.producto_id]*descuento_por_producto);
                  console.log('entró en el 1',temp_total);
                }
                if (inputValues[p.producto_id]>cantidad_productos_con_descuento  && descuento_por_producto>0) {
                  console.log('entró en el 2',temp_total); 
                  temp_total=(p.valor * inputValues[p.producto_id])-(inputValues[p.producto_id]*descuento_por_producto);
                }
              }else{
                temp_total=(p.valor * inputValues[p.producto_id]);
                console.log("temp_total",temp_total)
              }
              
            }
            
            
            total_d+=temp_total;
            //total += p.valor * inputValues[p.id];
            //console.log(inputValues[p.id]);
          }
        }
        );
       
      }
      console.log('EL TOTAL ES: ',total_d);

      
      settotalDescuento(total_d);
      
  }

  const currency = function (number) {
    return '$ ' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  useEffect(() => {
    if (evento !== null) {
      cargarProductos(evento.id).then((e) => {
        setproductos(e);
      });
    } else {
      cargarComuna().then((c) => {
        setcomunas(c);
      });
      cargarEvento(slug).then((p) => {
        setevento(p);
      
        setcargando(false);
        if (p === null) {
          Swal.fire({
            title: 'Error!',
            text: 'El evento seleccionado no está disponible',
            icon: 'error',
            confirmButtonText: 'Entendido'
          }).then(() => {
            navigate('/');
          })

        }
      });
    }

  }, [evento]);

  useEffect(() => {
    calcularTotalDescuento();
  }, [inputValues,descuento])
  
  useEffect(() => {
    setsubTotalDescuento(totalCarrito-totalDescuento);
   
  }, [totalDescuento])
  

  function updateTotalCarrito() {
    let total = 0;
    if (productos !== null) {
      productos.forEach((p) => {
        if (typeof inputValues[p.id] !== 'undefined') {
          total += p.valor * inputValues[p.id];
          //console.log(inputValues[p.id]);
        }
      }
      );
    }
    settotal(total);
    settotalCarrito(total);

  }

  useEffect(() => {
    setTimeout(() => {

    }, 500);
    updateTotalCarrito();

  }, [inputValues, productos]);


  const handleChangeCantidad = ({ target }) => {

    //BORRAR LETRAS Y QUE NO SEA MENOR Y QUE SEA MENOR A LA CANTIDAD MÁXIMA
    setInputValues({
      ...inputValues,
      [target.id]: target.value

      //[target.id]: target.value
    })

  }

  const handleChangeCantidadManual = (id,valor) => {

    //BORRAR LETRAS Y QUE NO SEA MENOR Y QUE SEA MENOR A LA CANTIDAD MÁXIMA
    setInputValues({
      ...inputValues,
      [id]: valor

      //[target.id]: target.value
    })

  }

  const handlePlus = ({ target }) => {

    
    let maximoPorJornada=maximoProductos;
    if (maximoJornada!=null) {
        
        for (const key in maximoJornada) {
          if (Object.hasOwnProperty.call(maximoJornada, key)) {
            const element = maximoJornada[key];
            console.log('maximo permitido x jornada disccount',element);
          
            if (element["producto_id"]==target.dataset.id) {
                
                if (element["disponibles"]>0) {
                    maximoPorJornada=element["disponibles"];
                }/*else{
                  setdescuento(null);
                }*/
                console.log(element["producto_id"],target.dataset.id,element["disponibles"]);
            }
          }
        }
    }

    if (descuento!=null) {
        
          descuento.map((datos,indice)=>{
            if (datos.producto_id==target.dataset.id && datos.disponibles_para_descuento>0) {
              console.log("1 disponibles para descuento",datos.disponibles_para_descuento )
              maximoPorJornada=datos.disponibles_para_descuento;
            }
            if(datos.producto_id==target.dataset.id && datos.disponibles_para_descuento==0){
             
              maximoPorJornada=maximoProductos;
              console.log("se acabó el descuento",maximoPorJornada)
            }
        })
        
    }

    if (typeof inputValues[target.dataset.id] === 'undefined' && maximoPorJornada>0) {
      setInputValues({
        ...inputValues,
        [target.dataset.id]: 1
      })
    } else {
      
      if (inputValues[target.dataset.id] < maximoPorJornada) {
        console.log('el máximo es',maximoPorJornada)
        setInputValues({
          ...inputValues,
          [target.dataset.id]: inputValues[target.dataset.id] + 1
        })
      }
    }

  }


  const handleMinus = ({ target }) => { 
    console.log('inputvalues',inputValues);
    if (typeof inputValues[target.dataset.id] === 'undefined') {
      setInputValues({
        ...inputValues,
        [target.dataset.id]: 0
      })
    } else if (inputValues[target.dataset.id] > 0) {
      setInputValues({
        ...inputValues,
        [target.dataset.id]: inputValues[target.dataset.id] - 1
      })
    }

  }

  const getValue = ({ target }) => {
    return '0';
  }

  function totalProductos(){
    var cantidad=0;
    for (const key in inputValues) {
      if (Object.hasOwnProperty.call(inputValues, key)) {
        const element = inputValues[key];
        cantidad=cantidad+element;
      }
    }
    return cantidad;
  }

  const handlePagar = () => {
    setcargando(true);
    try {
      setemailError(false);
      setemail2Error(false);
      setnombreError(false);
      setapellidoError(false);
      settelefonoError(false);
      setcomunaError(false);
      setrutError(false);

      let datosValidos = true;

      /*
      if (totalProductos()>maximoProductos) {
        Swal.fire({
          title: 'Error!',
          text: `Solo puedes comprar ${maximoProductos} entradas con tu descuento el día de hoy`,
          icon: 'error',
          confirmButtonText: 'Entendido'
        })
        datosValidos = false;
        setcargando(false);
        return;
      }*/
      if (maximoJornada!=null) {
        console.log("maxjornada",maximoJornada);
        let mensaje_disponibles=``;
          for (const key in maximoJornada) {
            if (Object.hasOwnProperty.call(maximoJornada, key)) {
              const element_maximos = maximoJornada[key];
    
              for (const key_values in inputValues) {
                if (Object.hasOwnProperty.call(inputValues, key_values)) {
                  const productos_compra = inputValues[key_values];
                  if (element_maximos["producto_id"]==key_values) {
                    console.log("producto:",element_maximos["nombre"],"disponibles ",element_maximos["disponibles"],'lleva: ',productos_compra);
                      if (productos_compra>element_maximos["disponibles"]) {
                          mensaje_disponibles=`${mensaje_disponibles}Solo puedes reservar ${element_maximos["disponibles"]} entradas para ${element_maximos["nombre"]} y llevas ${productos_compra} <br>`;
                      }
                  }
                }
              }
            
            }
          }

          if (mensaje_disponibles!=``) {
              Swal.fire({
                title: 'Antes de continuar',
                html: mensaje_disponibles,
                icon: 'info',
                confirmButtonText: 'Entendido'
              })
              datosValidos = false;
              setcargando(false);
              return;
          }
      }

      if (email === null || email === '') {
        Swal.fire({
          title: 'Error!',
          text: 'Debes completar todos los campos',
          icon: 'error',
          confirmButtonText: 'Entendido'
        })
        datosValidos = false;
        setcargando(false);
        setemailError(true);
      } else {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!email.match(validRegex)) {
          Swal.fire({
            title: 'Error!',
            text: 'El formato del correo es inválido',
            icon: 'error',
            confirmButtonText: 'Entendido'
          })
          datosValidos = false;
          setemailError(true);
        }
        setcargando(false);

      }

      //EMAIL2
      if (email2 === null || email2 === '') {
        Swal.fire({
          title: 'Error!',
          text: 'Debes completar todos los campos',
          icon: 'error',
          confirmButtonText: 'Entendido'
        })
        datosValidos = false;
        setcargando(false);
        setemail2Error(true);
      } else {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!email2.match(validRegex)) {
          Swal.fire({
            title: 'Error!',
            text: 'El formato del correo es inválido',
            icon: 'error',
            confirmButtonText: 'Entendido'
          })
          datosValidos = false;
          setemail2Error(true);
        }
        setcargando(false);

      }

      if (email !== email2) {
        Swal.fire({
          title: 'Error!',
          text: 'Los correos deben ser idénticos',
          icon: 'error',
          confirmButtonText: 'Entendido'
        })
        datosValidos = false;
        setemailError(true);
        setemail2Error(true);
        setcargando(false);
      }

      if (nombre === null || nombre === '') {

        datosValidos = false;
        setcargando(false);
        setnombreError(true);
      }

      if (comuna === null || comuna === '') {

        datosValidos = false;
        setcargando(false);
        setcomunaError(true);
      }

      if (apellido === null || apellido === '') {

        datosValidos = false;
        setcargando(false);
        setapellidoError(true);
      }

      if (formattedValue === null || formattedValue === '') {

        datosValidos = false;
        setcargando(false);
        setrutError(true);
      }

      if (telefono === null || telefono === '') {

        datosValidos = false;
        setcargando(false);
        settelefonoError(true);
      }
      if (datosValidos) {
        Swal.fire({
          title:'Confirmas que aceptas los términos y condiciones',
          html:'',
          icon:'question',
          showDenyButton:false,
          showCancelButton:true,
          confirmButtonText:'Si, continuar',
          cancelButtonText:'Cerrar',
          customClass:{
            cancelButton:'btn btn-secondary',
            confirmButton:'btn btn-success'
          }
        }).then((result)=>{
          if(result.isConfirmed){
            let carrito_ = {
              email: email,
              nombre: nombre,
              apellido: apellido,
              telefono: telefono,
              rut: formattedValue,
              comuna: comuna,
              evento_id: evento.id,
              productos: []
            };
    
            for (let i = 0; i < productos.length; i++) {
              let producto = productos[i];
              let cantidad = inputValues[producto.id];
              if (cantidad > 0) {
                carrito_.productos.push({
                  id: producto.id,
                  cantidad: cantidad
                })
              }
            }
    
            if (carrito_.productos.length > 0) {
              setcargando(true);
              localStorage.setItem('carrito', JSON.stringify(carrito_));
              //window.location.href = '/carrito';
              iniciarPago(carrito_).then((e) => {
                setcargando(false);
                if (e.status === 0) { //PAGO WEBPAY
                  settoken_ws(e.token);
                  seturl_ws(e.url);
    
                  window.location.href = api_url+'middleware?url=' + e.url + '&token=' + e.token;
                } else if (e.status === 4) { //PAGO GRATIS
                  window.location.href = e.url;
                } else {
                  Swal.fire({
                    title: 'Error!',
                    text: e.error_results,
                    icon: 'error',
                    confirmButtonText: 'Entendido'
                  })
                }
    
              })
    
            } else {
              Swal.fire({
                title: 'Error!',
                text: 'Debes seleccionar al menos un producto para poder pagar',
                icon: 'error',
                confirmButtonText: 'Entendido'
              })
              setcargando(false);
            }
          }
        })
        

      } else {
        Swal.fire({
          title: 'Error!',
          text: 'El formulario contiene errores, Debes completar todos los campos',
          icon: 'error',
          confirmButtonText: 'Entendido'
        })
      }



    } catch (error) {
      console.log(error);
      alert('Ha ocurrido un error');
    }
  }

  const validarDescuento = (value) => {
    //console.log(payload);
    if (mostrarComponenteDescuento) {
      cargarDescuento(value,evento["id"]).then((e) => {
        if (e.status === 0) {
          console.log('data disccount',e);
          setdescuento(e.productos);

          e.productos.map((producto,index)=>{
            handleChangeCantidadManual(producto.producto_id,0);
          })

          setmaximoJornada(10);
          //settotal()
          //setmensajeDescuento(e.mensaje);
          //setmaximoProductos(e.cantidad_permitida);
          
          //PRE SELECCIONAR PEÑAFLOR
          setcomuna('PEÑAFLOR');
          
        } else {
          setmaximoJornada(null);
          setmaximoProductos(10); //esto se cambia supongo
          setdescuento(null);
        }
      })
    }
  }

  

  return cargando ?
    <div className='loading'>
    </div>
    :
    (
      <div className='container-fluid'>

        <form
          id="ws_form"
          style={{ display: 'none' }}
          action={url_ws} method="POST">
          <input type="hidden" name="token_ws" value={token_ws} />
        </form>

        <div className="container pt-3">

          {
            mostrarComponenteDescuento ?
            evento !=null ?
            <div className='row d-none'>
                <div className='col-md-12 mb-5 text-center'>
                  {/*<h2>Eres vecino de Peñaflor tienes <br></br>un 50% de descuento</h2>
                  <br/>
          <small className='text-muted'>Válido hasta el 15 de septiembre.</small> */}
                  <img src={base_url+evento.imagen_2} className='bannerImage'></img>
                </div>
            </div>
            
            : <></> : <></>
          }
         

          <div className="row">
            <div className="col-lg-4">
              {
                evento !== null ?
                  <>
                    <div
                      className="meeta-event-schedule-banner"
                      style={{
                        backgroundImage: "url(" +base_url+ evento.imagen + ")"
                       // backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(" +base_url+ evento.imagen + ")"
                      }}
                    >
                      {
                        /*
                        <div className="meeta-section-title">
                        <h4 className="sub-title text-pink">Evento</h4>
                        <h2 className="main-title text-white">
                          {evento !== null ? evento.titulo : 'Cargando...'}
                        </h2>
                      </div>
                        */
                      }
                      
                      {
                        /*
                      }
                      <a href="schedule.html" className="btn btn-3 btn-primary">
                        Descargar Información <i className="fa fa-download" />
                      </a>
                      {
                        */
                      }
                    </div>
                    <div className='col-md-12 mt-3 ml-0 border p-2'>
                      <HTMLRenderer 
                        html={evento["cuerpo"]}
                      />
                    </div>
                  </>
                  :
                  <>Cargando..</>
              }


            </div>
            <div className="col-lg-8">
              <div className='row'>
              <div className='col-md-12 pb-3 mb-2'>
                      <div className="row">

                        <div className="card">
                          <div className='card-body'>
                            <div className='row'>
                              <div className="col-md-12 mb-3">
                                <h4>Datos personales</h4>
                              </div>

                              <div className="col-md-6 mt-3">
                                <div className='form-group'>
                                  <label>Rut</label>
                                  <input
                                    type="text"
                                    onChange={({ target }) => {
                                      setRut(target.value);
                                    }}
                                    onKeyUp={({ target }) => {
                                      setRut(target.value);
                                      validarDescuento(target.value)
                                    }}
                                    value={formattedValue}
                                    className={rutError ? `form-control is-invalid` : `form-control`}></input>
                                   
                                </div>
                              </div>

                              {
                                descuento == null ?
                                  /*<div className="col-md-6 mt-3">
                                    <div className='form-group'>
                                      <label>¿Eres vecino de Peñaflor y no se refleja el beneficio?</label>
                                      <a 
                                      href={url_descuentos}
                                      target="_blank"
                                      className="btn btn-primary btn-block">Registrate Aquí</a>
                                    </div>
                                  </div>*/
                                  <></>
                                : <div className='col-md-6 pt-4'>
                                    <h3>Descuento vecino: <b className="text-pink">Rut válido.</b></h3>   
                                    {
                                      maximoJornada!=null && maximoJornada.length >0 ?
                                      <>Puedes comprar un máximo de <b className="text-pink">{maximoJornada[0]["disponibles"]}</b> entradas con descuento.
                                      </> : <></>
                                    }  
                                  </div>
                                }

                              <div className="col-md-6 mt-3">
                                <div className='form-group'>
                                  <label>Nombre</label>
                                  <input type="text" onChange={({ target }) => { setnombre(target.value) }} className={nombreError ? `form-control is-invalid` : `form-control`}></input>
                                </div>
                              </div>

                              <div className="col-md-6 mt-3">
                                <div className='form-group'>
                                  <label>Apellido</label>
                                  <input type="text" onChange={({ target }) => { setapellido(target.value) }} className={apellidoError ? `form-control is-invalid` : `form-control`}></input>
                                </div>
                              </div>

                              <div className="col-md-6 mt-3">
                                <div className='form-group'>
                                  <label>Comuna</label>
                                  <select 
                                  ref={comunaRef}
                                  value={comuna}
                                  className={comunaError ? `form-control is-invalid` : `form-control`} 
                                  onChange={({ target }) => { setcomuna(target.value) }} >
                                    <option value="">-Seleccione su comuna-</option>
                                    {comunas !== null ? comunas.map((c, index) => {
                                      return <option key={`comuna_${index}`} value={c.nombre}>{c.nombre}</option>
                                    }) : <></>}
                                  </select>
                                  { /*<input type="text" onChange={({ target }) => { setapellido(target.value) }} className='form-control'></input> */}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='form-group'>
                                  <label>E-mail</label>
                                  <input type="text" onChange={({ target }) => { setemail(target.value) }} className={emailError ? `form-control is-invalid` : `form-control`}></input>
                                  <small><i className='fa fa-exclamation-triangle mr-3'></i>Verifica que tu dirección sea correcta ya que te enviarémos las entradas por correo electrónico.</small><br></br>
                                  <small>Prefiere cuentas @gmail, @icloud, etc. Hemos reportado inconvenientes al recibir correos con cuentas Microsoft (@hotmail, @live, @outlook)</small>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className='form-group'>
                                  <label>Confirmar E-mail</label>
                                  <input type="text" 
                                  onChange={({ target }) => { setemail2(target.value) }} 
                                  onPaste={(e)=>{e.preventDefault();}}
                                  className={email2Error ? `form-control is-invalid` : `form-control`}></input>
                                  <small>Confirma tu dirección de correo</small>
                                </div>
                              </div>

                              

                              

                              <div className="col-md-6">
                                <div className='form-group'>
                                  <label>Teléfono de contacto</label>
                                  {/*}<input type="text" onChange={({target})=>{settelefono(target.value)}} className='form-control'></input>{*/}
                                  <PhoneInput
                                    className={telefonoError ? `is-invalid` : ``}
                                    country={'CL'}
                                    international
                                    defaultCountry='CL'
                                    value={telefono}
                                    onChange={settelefono}
                                  />
                                </div>
                              </div>

                              

                            </div>
                          </div>
                        </div>



                      </div>
                    </div>

              </div>
              <div className="row">
                {productos !== null ?
                  productos.map((p, index) => {

                    return <div key={`li_${index}`} className="col-md-12 pt-3 pb-3 mb-2 border">
                      <div className="row">
                        <div className="col-md-4">
                          <h3>{p.nombre}</h3>
                          {/*<p>Cantidad disponible: <b className='text-pink'>{p.cantidad_disponible}</b></p>*/}
                          <p>Valor: <b className='text-pink'>{p.precio}</b></p>
                          { 
                          descuento!=null ?
                            descuento.map((datos,indice)=>{
                              if (datos.producto_id==p.id && datos.descuento_por_producto>0) {
                                return <>{currency(datos.descuento_por_producto)} de descuento por producto. {datos.cantidad_productos_con_descuento >0 ? 'Disponibles: '+datos.disponibles_para_descuento : ''}</>
                              }
                            }) : <></>
                          }
                        </div>
                        <div className="col-md-8">
                          <p>{p.descripcion}</p>
                          <label>Cantidad</label>
                          <div className="form-group">
                            <button
                              className='btn btn-secondary d-inline'
                              style={{ marginTop: -10 }}
                              data-id={p.id}
                              onClick={handleMinus}>-</button>
                            <input
                              id={`${p.id}`}
                              className="inputCantidad d-inline"
                              name="cantidad"
                              placeholder='0'
                              onChange={handleChangeCantidad}
                              value={inputValues[p.id]}
                              type="text"
                              readOnly
                            />
                            <button
                              className='btn btn-secondary d-inline'
                              style={{ marginTop: -10 }}
                              data-id={p.id}
                              onClick={handlePlus}>+</button>
                          </div>

                        </div>
                      </div>
                    </div>
                  })

                  : <>Cargando productos</>}
              </div>
              <div className='row'>

              </div>
              {
                productos !== null && productos.length > 0 ?
                  <div className='row'>
                    <div className='col-md-12 pt-2 pb-2'>
                      {
                        descuento !== null ?
                          <>
                            <h6>Total a descontar: <b className="text-pink">{currency(subTotalDescuento)}</b></h6>
                           
                            <h4>Total: <b className="precio-antes">{currency(totalCarrito)}</b> {total !== 0 ? <b className='text-pink '>{currency(totalDescuento)}</b> : <></>}</h4>
                          </>
                          :
                          <h1>Total: <b className="text-pink">{currency(totalCarrito)}</b></h1>
                      }
                    </div>
                  
                    <div className='col-md-6 text-left m-0 p-0'>
                      <a href="https://penaflor.cl/Terminosycondiciones.pdf" target="_blank">Conocer los términos & condiciones</a>
                    </div>
                    <div className='col-md-6 m-0 p-0 text-right'>
                      <button
                        type="button"
                        className='btn btn-success'
                        onClick={handlePagar}
                      >Continuar con el pago</button>
                    </div>
                  </div>
                  :
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className="alert alert-warning" role="alert">
                        <strong>Atención</strong> No hay productos disponibles.
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>

    )
}

export default Evento;